import GATSBY_COMPILED_MDX from "/opt/build/repo/content/works/sempre-libera-2016/index.mdx";
import React from 'react';
import {graphql} from 'gatsby';
import removeMarkdown from 'remove-markdown';
import yaml from 'js-yaml';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MdxRender from 'components/mdx-render';
import Meta from 'components/meta';
import RedirectToParent from 'components/redirect-to-parent';
import WorkDetail from 'components/work-detail';
import WorkPoster from 'components/work-poster';
import Wrapper from 'components/wrapper';
import {useGithubData} from 'utils/use-github-data';
function MdxWork({children, location, pageContext, data: {mdx}}) {
  const {frontmatter: fm, contentKey: workKey} = mdx ?? ({});
  const {projectData} = pageContext ?? ({});
  if (!projectData) return React.createElement(RedirectToParent, {
    location: location
  });
  const workData = Object.assign({}, projectData, Object.fromEntries(Object.entries(fm).filter(([key]) => ['columns', 'links', 'note'].includes(key))), {
    creditsBreak: fm?.credits_break,
    poster: fm?.poster,
    venuesBreak: fm?.venues_break,
    pages: projectData.pages?.filter(item => {
      return item.route != location?.pathname.replace(/\/$/, '');
    })
  });
  const github = useGithubData();
  const workRepo = github.find(o => o.type === 'tree' && o.name === workKey);
  if (workRepo?.object?.entries) {
    const creditsFile = workRepo.object.entries.find(({name}) => name === 'credits.yml');
    workData.credits = yaml.load(creditsFile?.object?.text);
    const descriptionFile = workRepo.object.entries.find(({name}) => name === 'description.md');
    workData.description = descriptionFile?.object?.text;
    const noteFile = workRepo.object.entries.find(({name}) => name === 'note.md');
    workData.note = noteFile?.object?.text;
    const taglineFile = workRepo.object.entries.find(({name}) => name === 'tagline.md');
    workData.tagline = taglineFile?.object?.text;
    const venuesFile = workRepo.object.entries.find(({name}) => name === 'venues.yml');
    workData.venues = yaml.load(venuesFile?.object?.text);
  }
  const workTitle = fm?.title ?? workData.title;
  const description = workTitle + (workData.year ? ` (${workData.year})` : '') + ' - ' + removeMarkdown(workData.tagline);
  if (workData.poster?.credit && workData.credits?.credit_items?.length) {
    workData.credits.credit_items.push({
      note: workData.poster.credit.label ?? 'Image Credit',
      name: workData.poster.credit.name
    });
    delete workData.poster.credit;
  }
  const renderHeader = () => {
    return React.createElement("div", {
      className: "my-2"
    }, React.createElement(Row, {
      className: "pt-1 align-items-end border-bottom border-secondary"
    }, React.createElement(Col, {
      xs: "12",
      lg: "8",
      className: "fs-3 media-title text-center text-lg-start"
    }, workTitle), React.createElement(Col, {
      xs: "12",
      lg: "4",
      className: "fs-4 mb-2 text-center text-lg-end"
    }, (workData.tags ?? []).map((tag, index) => React.createElement(Badge, {
      key: index,
      bg: "dark",
      className: "media-title p-1 me-2"
    }, tag)), workData.year && React.createElement(Badge, {
      bg: "dark",
      className: "media-title p-1"
    }, workData.year))));
  };
  return React.createElement(Wrapper, {
    location: location,
    variant: "project",
    wrapperClass: "d-flex flex-column w-100 mx-auto"
  }, React.createElement(Meta, {
    title: workTitle,
    author: fm?.author,
    description: description
  }), renderHeader(), workData.poster && React.createElement(WorkPoster, {
    caption: workData.poster.caption ?? workTitle,
    imagePath: `${workKey}/${workData.poster.name}`,
    credit: workData.poster.credit,
    wrapperClasses: "mb-1"
  }), React.createElement(WorkDetail, {
    workData: workData
  }, React.createElement(MdxRender, null, children)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxWork, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        author
        columns
        credits_break
        links {
          label
          url
        }
        note
        poster {
          caption
          credit {
            label
            name
          }
          label
          name
        }
        title
        venues_break
      }
      contentKey
    }
  }
`;
